import React from "react";

import Layout from "../../components/Layout";
import ResearchInfo from "../../epics/research/Research";
//import ResearchInfo from "../../epics/research/ResearchInfo";

const ResearchPage = () => (
  <Layout>
    {/*<ResearchInfo />*/}
  </Layout>
);

export default ResearchPage;

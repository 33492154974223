import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import color from "../common/styles/color";
import MEDIA from "../common/styles/media";

//linear-gradient(to left, ##2e967f, #0aa3ad)
// linear-gradient(to left, #F77DD6, #0E5E57D)

const Button = styled.a`
  background:  rgba(255, 255, 255, 0.4);//linear-gradient(270deg, #47e4c1, #07cddd);
  color: #fff;
  font-size: 25px;
  transition: all 0.2s ease;
  border-radius: 30px;
  min-width: 250px;
  border: none;
  //box-shadow: 0 4px 20px 2px rgba(187, 187, 187, 0.5);
  font-family: inherit;
  font-weight: 700;
  line-height: 30px;
  padding: 12px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  z-index: 9999999;
  &:hover {
    background: #fff;
    box-shadow: 0 2px 6px 1px rgba(187, 187, 187, 0.5);
    color: #000;
    transform: scale(0.98);
  }
  svg {
    font-size: 85%;
    margin: 0px 2px 0px 4px;
    transition: all 0.3s ease;
  }
  ${MEDIA.MOBILE`
    font-size: 18px;
    padding: 10px;
  `};
`;

const LargeButton = ({ children }) => (
  <Button href="http://localhost:3000/login" title="start">
    {children}
  </Button>
);

LargeButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default LargeButton;

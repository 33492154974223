import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import IO from "../../../components/pose/IO";
import StartButton from "../../../components/StartButton";
import color from "../../../common/styles/color";
import MEDIA from "../../../common/styles/media";
import { UpPose } from "../../../components/pose/Poses";
import LargeButton from "../../../components/LargeButton";
import WhiteButton from "../../../components/WhiteButton";

const ResearchHero = ({ data }) => {
  return (
    <IO>
      {({ isVisible, hasBeenVisible }) => (
        <>
          <div className="BackgroundTwinkle">
            <div id="stars"></div>
            <div id="stars2"></div>
          </div>
          <>
            <>
              <Wrapper>
                <div className="SchoolsBackground">
                  <UpPose
                    pose={isVisible || hasBeenVisible ? "visible" : "hidden"}
                  >
                    <div className="TopBannerContainer">
                      <div className="TopBannerText">
                        <Heading>
                          <strong>#</strong>EDEOSkills
                        </Heading>

                        <Text>
                          Discover the 5 future skillls to be future ready.
                        </Text>
                      </div>
                      {/*<figure>
          {!data.header.image.childImageSharp ||
          data.header.image.extension === "svg" ? (
            <img src={data.header.image.publicURL} alt={data.header.subtitle} />
          ) : (
            <Img
              fluid={
                data.header.image ? data.header.image.childImageSharp.fluid : {}
              }
              alt={data.header.subtitle}
            />
          )}
            </figure>*/}
                    </div>
                  </UpPose>
                </div>
              </Wrapper>
            </>
          </>
        </>
      )}
    </IO>
  );
};

export default ResearchHero;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: grid;
  min-height: 600px;
  max-height: 65px;
  height: 80vh;
  justify-content: space-between;
  grid-template-columns: 550px 3fr;
  grid-gap: 4rem;
  overflow: hidden;
  margin-bottom: -100px;
  ${MEDIA.MOBILE`
    grid-template-columns: 100%;
    min-height: 600px;
  `};
`;

const Heading = styled.div`
  margin: 0 12%;
  text-align: center;
  color: #fff;
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 20px;
  ${MEDIA.TABLET`
    margin: 10vw 10vw 10vw 12vw;
  `};
  ${MEDIA.MOBILE`
    margin: 12vw 10vw 10vw 10vw;
  `};
`;

const Text = styled.div`
  //margin: 0 12%;
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  ${MEDIA.TABLET`
    margin: 10vw 10vw 10vw 12vw;
  `};
  ${MEDIA.MOBILE`
    margin: 12vw 10vw 10vw 10vw;
  `};
`;
const Title = styled.h1`
  color: ${color.black};
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1;
  ${MEDIA.MOBILE`
    font-size: 65px;
    letter-spacing: -2.8px;
  `};
`;

const Highlight = styled.h1`
  color: ${color.purple};
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1;
  ${MEDIA.MOBILE`
    font-size: 65px;
    letter-spacing: -2.8px;
  `};
`;

const Description = styled.div`
  margin: 10px 0px 20px;
  line-height: 1.6;
  letter-spacing: -0.7px;
  font-size: 22px;
  ${MEDIA.MOBILE`
    font-size: 18px;
    letter-spacing: -0.6px;
  `};
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  z-index: 999999;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
  }
  a {
    font-size: 22px;
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;
    align-items: center;
  }
  ${MEDIA.MOBILE`
      a {
      font-size: 15px;
    }
  `}
`;

const Comment = styled.p`
  margin-top: 20px;
  font-size: 15px;
  color: ${color.blackLight};
  letter-spacing: -0.4px;
  ${MEDIA.MOBILE`
    font-size: 13px;
    letter-spacing: -0.3px;
  `};
`;

const Figure = styled.figure`
  width: 130%;
  min-width: 980px;
  margin-top: 20px;
  ${MEDIA.TABLET`
    display: none;
  `};
`;
